<template>
	<el-dialog title="请假记录审核" :close-on-click-modal="false" :visible.sync="visible" width="700px">
		<el-form :model="dataForm" :rules="dataRule" ref="dataForm" label-width="120px" :inline="true" class="border-form">
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="请假时间" class="item">
						<el-date-picker class="selItemInput" v-model="checkedTime" type="daterange"
							value-format="yyyy-MM-dd" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
						</el-date-picker>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="set_border">
				<el-col :span="24">
					<el-form-item label="上传文件" class="item">
						<div class="addFileBox" v-for="(item, index) in fileList">
							<div>
								<span
									style="display: inline-block; width: 200px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis; vertical-align: top;">
									{{ item.fileName }}
								</span>
								<i class="el-icon-delete" @click="delFile(index)"></i>
							</div>
						</div>
						<el-upload v-if="fileList.length == 0" class="addFileBox" ref="uploadFile" :action="action"
							:http-request="uploadFile">
							<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-folder-add"
								style="padding: 9px 0 !important;">添加文件</el-button>
						</el-upload>
					</el-form-item>
				</el-col>
			</el-row>
			<el-row class="formBtnGroup">
				<el-button v-preventReClick class="btn" type="primary" size="small" @click="dataFormSubmit()">保存</el-button>
				<el-button v-preventReClick class="rebtn" size="small" @click="visible = false">取消</el-button>
			</el-row>
		</el-form>
	</el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
	name: "leavetime-checked",
	data() {
		return {
			id: "",
			visible: true,
			form: {},
			action: "",
			checkedTime: "",
			fileList: [],
			dataForm: {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				beginTime: "",
				endTime: "",
				filePath: "",
			},
			dataRule: {

			},
		};
	},
	components: {

	},
	activated() {

	},
	methods: {
		init() {
			this.dataForm = {
				workNo: $common.getItem("workNo"),
				subjectNo: $common.getItem("subjectNo"),
				beginTime: "",
				endTime: "",
				filePath: "",
			};
			this.fileList = []
			this.$nextTick(() => {
				this.visible = true;
			})
		},
		uploadFile(param) {
			const formData = new FormData();
			formData.append('file', param.file);
			this.$refs.uploadFile.clearFiles();
			this.$http.post(
				this.$store.state.httpUrl + "/file/upload/" + $common.getItem("workNo") + "/99/0",
				formData,
				{
					headers: { 'Content-Type': 'multipart/form-data' }
				},
			).then(res => {
				res = res.data
				if (res.resultCode == 200) {
					this.fileList = [{
						fileName: res.body.fileName,
						filePath: res.body.fileSavePath,
						fileUri: res.body.fileUri
					}];
					this.dataForm.filePath = res.body.fileSavePath;
				} else {
					this.$message.info('文件上传失败')
				}
			})
		},
		dataFormSubmit() {
			this.$refs["dataForm"].validate(valid => {
				if (valid) {
					let _dataForm = this.dataForm;
					_dataForm.beginTime = this.checkedTime[0] ? this.checkedTime[0] : "";
					_dataForm.endTime = this.checkedTime[1] ? this.checkedTime[1] : "";
					this.$http({
						url: this.$store.state.httpUrl + "/business/leaveinfo/saveLeaveInfoCheck",
						method: "post",
						data: this.$http.adornData(_dataForm)
					}).then(({ data }) => {
						if (data && data.resultCode === 200) {
							this.$message({
								message: "操作成功",
								type: "success",
								duration: 1500,
								onClose: () => {
									this.visible = false;
									this.$emit("refreshDataList");
								}
							});
						} else {
							this.$message.error(data.msg);
						}
					});
				}
			});
		}
	}
};
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
	width: 718px !important;
}

.addImgBox {
	width: 136px;
	height: 136px;
	display: inline-block;
	vertical-align: middle;
	margin-right: 10px;
}

.item::v-deep .el-form-item__label {
	color: #00428E !important;
	margin-right: 10px !important;
	font-weight: bold !important;
	background-color: #E1F1FF;
	white-space: nowrap !important;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border {
	border-bottom: solid #dadada 1px;
	border-left: solid #dadada 1px;
	border-right: solid #dadada 1px;
}

.border-form .el-row.set_border:first-child {
	border-top: solid #dadada 1px;
}

::v-deep .el-form-item {
	display: flex !important;
	align-items: stretch !important;
	margin-bottom: 0px !important;
}
</style>
